<template>
  <div class="vp-event-detail">
    <div class="vp-breadcrumbs">
      <b-container>
        <b-row>
          <b-col cols="12">
            <router-link
                :to="{ name: 'home' }"
                class="vp-breadcrumb"
            >
              <!-- eslint-disable -->
              <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path d="m8 16 1.41-1.41L3.83 9H16V7H3.83l5.59-5.58L8 0 0 8z" fill="#FFF" fill-rule="evenodd"/>
              </svg>

              <!-- eslint-ensable -->
              <span>Terug naar overzicht</span>
              <svg class="half-dots" id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 16">
                <g id="c"><path d="M32,16H0C0,7.16,7.16,0,16,0s16,7.16,16,16Z" style="fill:#fff;"/>
                  <path d="M76,16H44C44,7.16,51.16,0,60,0s16,7.16,16,16Z" style="fill:#fff;"/>
                  <path d="M120,16h-32c0-8.84,7.16-16,16-16s16,7.16,16,16Z" style="fill:#fff;"/>
                </g>
              </svg>
            </router-link>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container class="vp-event-single-container">
      <b-row v-if="event.title" class="vp-title">
        <b-col cols="12" lg="7" offset-lg="2">
          <h1 class="vp-event-title">
            <span v-if="event.isPostponed" class="vp-status">Uitgesteld |</span>
            <span v-else-if="event.isCancelled" class="vp-status">Geannuleerd |</span>
            <span v-else-if="event.isSoldOut" class="vp-status">Uitverkocht |</span>
            {{ event.title }}
          </h1>
        </b-col>
      </b-row>

      <b-row v-if="event.subtitle" class="vp-details">
        <b-col cols="12" lg="7" offset-lg="2">
          <div class="vp-event-details">
            <span>{{event.subtitle}}</span>
          </div>
        </b-col>
      </b-row>

      <b-row v-else-if="event.location || event.startDate || event.endDate" class="vp-details">
        <b-col cols="12" lg="7" offset-lg="2">
          <div class="vp-event-details">
            <span v-if="event.startDate || event.endDate" class="vp-event-dates">
              <span
                  v-if="event.startDate && event.endDate && (event.startDate != event.endDate)"
                  v-html="`${formatDate(event.startDate, 'D MMMM', 'D MMMM YYYY')} t/m ${formatDate(event.endDate, 'D MMMM', 'D MMMM YYYY')}, `"
              />
              <span
                  v-else-if="event.startDate"
                  v-html="`${formatDate(event.startDate, 'D MMMM', 'D MMMM YYYY')}, `"
              />
            </span>
            <span v-if="event.location" class="vp-event-location">
              <span
                  v-if="event.location.name" class="vp-event-location-name"
                  v-html="`${event.location.name}`"
              />
              <span
                  v-if="event.location.cityName" class="vp-event-location-city"
                  v-html="` in ${event.location.cityName}`"
              />
            </span>
          </div>
        </b-col>
      </b-row>

      <b-row class="vp-featured-image">
        <b-col cols="12" lg="8" offset-lg="2">
          <div class="vp-featured-image-container">
            <figure>
              <img
                  :class="{'vp-opaque': !imageLoaded}"
                  :src="imageSrc"
                  :title="imageTitle"
                  alt=""
                  @load="imageLoaded = true"
              />
              <div
                  v-if="typeof event.images !== 'undefined'
                && event.images[0]
                && event.images[0].copyright"
                  class="vp-featured-image-credit"
              >
                <span>Foto: {{ event.images[0].copyright }}</span>
              </div>
            </figure>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="event.widgetIntro" class="vp-intro">
        <b-col cols="12" lg="7" offset-lg="2">
          <div
              class="vp-event-intro vp-event-text"
              v-html="event.widgetIntro"
          />
        </b-col>
      </b-row>

      <b-row class="vp-event-ticket">
        <b-col cols="12" lg="7" offset-lg="2">
          <EventTicketBlock :event="event"/>
        </b-col>
      </b-row>

      <b-row
          v-if="event.description"
          class="vp-content"
      >
        <b-col cols="12" lg="7" offset-lg="2">
          <p
              v-if="event.description"
              class="vp-event-text"
              v-html="event.description"
          />
        </b-col>
      </b-row>

      <b-row v-if="occurrences.length" class="vp-times">
        <b-col cols="12" lg="7" offset-lg="2">
          <h2 class="oc-table-title">Tijden</h2>
          <table class="vp-time-table oc-table">
            <tr
                v-for="(item, index) in occurrences"
                :key="index"
            >
              <td class="oc-date" v-html="item.date" />
              <td class="oc-time" v-html="item.time" />
            </tr>
          </table>
          <p v-if="event.masterDescription" class="vp-time-information vp-retain-margin">
            Toelichting: {{ event.masterDescription }}
          </p>
        </b-col>
      </b-row>

      <b-row v-if="showEventPrices()" class="vp-prices">
        <b-col cols="12" lg="7" offset-lg="2">
          <h2 class="oc-table-title">Prijzen</h2>

          <p v-if="event.isFree" class="vp-free-entry vp-event-text vp-retain-margin">Entree: Gratis</p>
          <table v-else class="oc-table">
            <thead>
            <th><!-- spacer --></th>
            <th>Kassa</th>
            <th v-if="event.hasPresale">Voorverkoop</th>
            </thead>

            <tbody>
            <tr v-if="event.isAdultFree || event.adultFee > 0 || (event.hasPresale && event.presaleAdultFee)">
              <td>Volwassenen</td>
              <td v-if="event.isAdultFree">Gratis</td>
              <td v-else-if="event.adultFee > 0">&euro; {{ event.adultFee }}</td>
              <td v-if="event.hasPresale">
                  <span v-if="event.presaleAdultFee">
                    &euro; {{ event.presaleAdultFee }}
                  </span>
              </td>
            </tr>
            <tr v-if="event.isChildFree || event.childFee > 0 || (event.hasPresale && event.presaleChildFee)">
              <td>Kinderen</td>
              <td v-if="event.isChildFree && !event.childFee && !event.childFeeStartAge && !event.childFeeEndAge">Gratis</td>
              <td v-else>
                <div v-if="event.childFeeEndAge > 0 && !event.childFeeStartAge && !event.childFee">
                  Gratis <span class="vp-price-condition">(t/m {{ event.childFeeEndAge }} jaar)</span>
                </div>
                <div v-else-if="event.childFee > 0">
                  &euro; {{ event.childFee }}
                  <span v-if="event.childFeeStartAge && event.childFeeEndAge" class="vp-price-condition">
                      ({{ event.childFeeStartAge }} t/m {{ event.childFeeEndAge }} jaar)
                  </span>
                  <span v-else-if="event.childFeeStartAge && !event.childFeeEndAge" class="vp-price-condition">
                      (vanaf {{ event.childFeeStartAge }} jaar)
                  </span>
                  <span v-else-if="!event.childFeeStartAge && event.childFeeEndAge > 0" class="vp-price-condition">
                      (t/m {{ event.childFeeEndAge }} jaar)
                  </span>
                </div>
              </td>
              <td v-if="event.isPresaleChildFree && !event.presaleChildFee && !event.presaleChildFeeStartAge && !event.presaleChildFeeEndAge && event.hasPresale">Gratis</td>
              <td v-else-if="event.hasPresale">
                <div v-if="event.presaleChildFeeEndAge > 0 && !event.presaleChildFeeStartAge && !event.presaleChildFee">
                  Gratis <span class="vp-price-condition">(t/m {{ event.presaleChildFeeEndAge }} jaar)</span>
                </div>

                <div v-else-if="event.presaleChildFee > 0">
                  &euro; {{ event.presaleChildFee }}
                  <span v-if="event.presaleChildFeeStartAge && event.presaleChildFeeEndAge" class="vp-price-condition">
                      ({{ event.presaleChildFeeStartAge }} t/m {{ event.presaleChildFeeEndAge }} jaar)
                  </span>
                  <span v-else-if="event.presaleChildFeeStartAge && !event.presaleChildFeeEndAge" class="vp-price-condition">
                      (vanaf {{ event.presaleChildFeeStartAge }} jaar)
                  </span>
                  <span v-else-if="!event.presaleChildFeeStartAge && event.presaleChildFeeEndAge > 0" class="vp-price-condition">
                      (t/m {{ event.presaleChildFeeEndAge }} jaar)
                  </span>
                </div>
              </td>
            </tr>
            <tr v-if="event.seniorFee || event.isSeniorFree || (event.hasPresale && event.presaleSeniorFee)">
              <td>65 plus</td>
              <td v-if="event.isSeniorFree">Gratis</td>
              <td v-else-if="event.seniorFee > 0">&euro; {{ event.seniorFee }}</td>
              <td v-if="event.hasPresale">
                  <span v-if="event.presaleSeniorFee">
                    &euro; {{ event.presaleSeniorFee }}
                  </span>
              </td>
            </tr>
            <tr v-if="event.cjpFee || event.isCjpFree || (event.hasPresale && event.presaleCjpFee)">
              <td>CJP</td>
              <td v-if="event.isCjpFree">Gratis</td>
              <td v-else-if="event.cjpFee > 0">&euro; {{ event.cjpFee }}</td>
              <td v-if="event.hasPresale">
                  <span v-if="event.presaleCjpFee">
                    &euro; {{ event.presaleCjpFee }}
                  </span>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>

        <b-col v-if="event.ticketSale || event.presaleDescription" cols="12" lg="7" offset-lg="2">
          <p v-if="event.ticketSale" class="vp-price-information vp-retain-margin">
            Toelichting: {{event.ticketSale }}
          </p>
          <p v-if="event.presaleDescription" class="vp-price-information vp-retain-margin">
            Voorverkoop: {{event.presaleDescription }}
          </p>
        </b-col>
      </b-row>

      <b-row
          v-if="
            event.location &&
            (event.location.longitude && event.location.latitude) &&
            !this.endPostalCodeWithZZ(event.location)
          " class="vp-map"
      >
        <b-col v-if="!isLoading" cols="12" lg="7" offset-lg="2">
          <span class="vp-h2">Locatie</span>
          <GoogleMap :latlong="latlng()" />
        </b-col>

        <b-col cols="12" lg="7" offset-lg="2">
          <EventAddress :event="event" />
        </b-col>
      </b-row>

      <b-row class="vp-socials">
        <b-col cols="12" lg="7" offset-lg="2">
          <EventSocials :event="event" />
        </b-col>
      </b-row>

      <b-row v-if="event.images && event.images.length">
        <b-col cols="12" lg="7" offset-lg="2">
          <EventImages :images="event.images" />
        </b-col>
      </b-row>

      <b-row v-if="event.videos && event.videos.length">
        <b-col cols="12" lg="7" offset-lg="2">
          <EventVideos :videos="event.videos" />
        </b-col>
      </b-row>
    </b-container>

    <FooterMenu/>
  </div>
</template>

<script>
import _ from "lodash";
import trimHtml from 'trim-html';
import moment from "moment";

import EventAddress from "@/components/EventAddress";
import EventImages from "@/components/EventImages";
import EventVideos from "@/components/EventVideos";
import EventSocials from "@/components/EventSocials";
import EventTicketBlock from "@/components/EventTicketBlock";
import FooterMenu from '@/components/Footer.vue';
import GoogleMap from "@/components/GoogleMap";

import mixin from '@/mixins/mixin.js';

moment.locale('nl');

export default {
  // eslint-disable-next-line
  name: 'Event',
  components: {
    EventAddress,
    EventImages,
    EventSocials,
    EventTicketBlock,
    FooterMenu,
    EventVideos,
    GoogleMap,
  },
  mixins: [mixin],
  data() {
    return {
      event: {},
      id: this.$route.params.id,
      imageLoaded: false,
      imageSrc: '',
      imageTitle: '',
      isLoading: true,
      occurrences: [],
    };
  },
  created() {
    this.id = this.id.split("-").pop();
    this.getEvent(this.id).then((response) => {
      this.isLoading = false;
      this.event = response;

      // Check whether we have a featured image, if not show a placeholder
      if (typeof this.event.images !== 'undefined'
          && this.event.images[0]
      ) {
        // Check if we have a featured image URL
        if (this.event.images[0].url) {
          this.imageSrc = this.event.images[0].url;
        } else {
          this.imageSrc = `${process.env.VUE_APP_VANPLAN_BASEURL}/img/vanplan_placeholder.png`;
        }

        // Check if we have to give attribution
        if (this.event.images[0].copyright) {
          this.imageTitle = this.event.images[0].copyright;
        }
      } else {
        // Compiled image path
        this.imageSrc = `${process.env.VUE_APP_VANPLAN_BASEURL}/img/vanplan_placeholder.png`;
      }

      // Display a short description if external.
      if (this.external) {
        const desc = trimHtml(this.convertLineEndings(this.event.description), { limit: 500 });
        this.event.description = desc.html;
      } else if (this.event.masterHtmlContent) {
        // Overwrite description if masterHtmlContent is available.
        this.event.description = this.event.masterHtmlContent;
      }

      this.event.description = this.convertLineEndings(this.event.description);

      // Get the difference between the end and start date.
      const start = moment(this.event.startDate);
      const end = moment(this.event.endDate);
      const daysDiff = end.diff(start, 'days');

      if (daysDiff >= 40) {
        // The event lasts longer than 40 days(i.e. expositions).
        // Just print the weekdays with the times.
        // isLongRunning = 40 days.

        response.occurrences.forEach((item) => {
          // Skip events that are sold out etc.
          if (!item.isSoldOut && !item.isPostPoned && !item.isCancelled) {
            this.occurrences.push(this.getOccurrenceRow(item, 'dddd'));
          }
        });

        // Only unique weekdays and their values.
        this.occurrences = _.uniqWith(this.occurrences, _.isEqual);

        // Sort the array to this order:
        const daysSorter = {
          zondag: 0,
          maandag: 1,
          dinsdag: 2,
          woensdag: 3,
          donderdag: 4,
          vrijdag: 5,
          zaterdag: 6,
        };

        this.occurrences.sort((a, b) => {
          const day1 = a.date.toLowerCase();
          const day2 = b.date.toLowerCase();
          return daysSorter[day1] - daysSorter[day2];
        });
      } else if (response.occurrences.length === 1) {
        // If startDate equals endDate and startTime and endTime are empty, do nothing.

        // If startDate equals endDate and startTime and endTime are NOT empty:
        // display date and time.
        if (
            (response.occurrences[0].startDate === response.occurrences[0].endDate)
            && response.occurrences[0].startTime !== ''
        ) {
          this.occurrences.push(this.getOccurrenceRow(response.occurrences[0]));
        }
      } else if (response.occurrences.length > 1) {
        // All occurrences are displayed.
        response.occurrences.forEach((item) => {
          this.occurrences.push(this.getOccurrenceRow(item));
        });
      }
    });
  },
  methods: {
    getOccurrenceRow(occurrence, format = 'dd D MMMM YYYY') {
      let date = '';
      let time = '';

      if (occurrence.startDate) {
        date = moment(occurrence.startDate).format(format);
      }

      if (
          !occurrence.isSoldOut
          && !occurrence.isCancelled
          && !occurrence.isPostPoned
          && occurrence.startTime
      ) {
        time = `${occurrence.startTime} uur`;

        if (occurrence.endTime) {
          time += ` tot ${occurrence.endTime} uur`;
        } else {
          time = `Vanaf ${time}`;
        }
      }

      if (occurrence.isSoldOut) {
        time = '<span class="vp-time-update">Uitverkocht</span>';
      }

      if (occurrence.isCancelled) {
        time = '<span class="vp-time-update">Geannuleerd</span>';
      }

      if (occurrence.isPostPoned) {
        time = '<span class="vp-time-update">Uitgesteld</span>';

        if (occurrence.newDate) {
          time += ` naar ${moment(occurrence.newDate).format('dd D MMMM YYY')}`;
        }
      }

      return {
        date,
        time,
      };
    },
    latlng() {
      const latlng = {
        lat: Number(this.event.location.latitude),
        lng: Number(this.event.location.longitude),
      };

      return latlng;
    },
    showEventPrices() {
      // Check if even one price is filled in.
      return (
          this.event.isAdultFree
          || this.event.adultFee > 0
          || (this.event.hasPresale && this.event.presaleAdultFee)
      )
      || (
          this.event.isChildFree
          || this.event.childFee > 0
          || (this.event.hasPresale && this.event.presaleChildFee)
      )
      || (
          this.event.seniorFee
          || this.event.isSeniorFree
          || (this.event.hasPresale && this.event.presaleSeniorFee)
      )
      || (
          this.event.cjpFee
          || this.event.isCjpFree
          || (this.event.hasPresale && this.event.presaleCjpFee)
      )
      || this.event.isFree;
    },
    convertLineEndings(html) {
      return html.replaceAll(new RegExp('([^>])(\\r\\n)([^<])', 'g'), '$1<br />$3');
    },
    endPostalCodeWithZZ(location) {
      return location.postalCode.slice(-2).toLowerCase() === 'zz';
    }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/_variables.scss';

.vp-event-detail {
  &::before {
    content: 'mobile';
    display: none;
  }

  h2 {
    color: $text-color;
    font-size: $fs-lg;
    line-height: $lh-xs;
    margin-bottom: $spacing-3xs;
  }
}

.vp-event-single-container {
  --bs-gutter-x: 1.5rem;
}

.vp-breadcrumbs {
  background-color: #000;
  color: #fff;
  font-size: $fs-2xl;
  line-height: 1;
  margin-bottom: $spacing-5xl;

  svg {
    color: inherit;
    fill: currentColor;
    margin-right: $spacing-5xs;
    max-height: 1em;
    width: auto;
  }

  .vp-breadcrumb {
    align-items: center;
    display: flex;
    padding: $spacing-default 0;
    position: relative;

    svg.half-dots {
      bottom: 0;
      height: 0.5rem;
      position: absolute;
      right: 0;
      width: auto;
    }
  }
}

.vp-featured-image-container {
  display: flex;
  margin-bottom: $spacing-2xl;

  img {
    display: block;
  }
}

.vp-featured-image-credit {
  color: $quiet;
  font-size: $fs-xs;
  line-height: $lh-sm;
}

.vp-intro {
  font-size: $fs-lg;
  font-weight: $fw-medium;
  line-height: $lh-sm;
  margin-bottom: $spacing-2xl;
}

.vp-event-title {
  font-size: $fs-6xl;
  font-weight: $fw-black;
  line-height: $lh-3xs;
  margin-bottom: $spacing-2xl;
}

.vp-event-details {
  font-size: $fs-lg;
  font-weight: $fw-medium;
  line-height: $lh-sm;
  margin-bottom: $spacing-2xl;
}

.vp-event-text {
  font-size: $fs-regular;
  line-height: $lh-lg;
  margin-bottom: $spacing-2xl;

  h2 {
    margin-bottom: $spacing-2xl;
  }

  p,
  ul,
  ol,
  div {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    margin-bottom: $spacing-2xl;
  }
}

.oc-table-title {
  font-size: $fs-lg;
  line-height: $lh-xs;
  margin-bottom: $spacing-default;
}

.oc-table {
  margin-bottom: $spacing-2xl;
  table-layout: fixed;
  width: 100%;

  th {
    display: none;
  }

  tr {
    font-size: $fs-xs;
    line-height: $lh-xl;

    &:nth-child(odd) {
      background-color: $brand-primary;
      color: $brand-primary-text;
    }

    &:nth-child(even) {
      background-color: $body-bg-color;
      color: $text-color;
    }

    td {
      padding: $spacing-xs 0 $spacing-sm;

      &:first-child {
        padding-left: $spacing-3xs;
      }

      &:not(:first-child) {
        padding-right: $spacing-3xs;
      }
    }
  }
}

.vp-price-condition {
  font-size: $fs-regular;
}

.vp-time-table .oc-date:first-letter {
  text-transform: uppercase;
}

.vp-time-information,
.vp-price-information {
  font-size: 1rem;
  line-height: $lh-lg;

  &:not(:last-child) {
    margin-bottom: $spacing-5xs;
  }

  &:last-child {
    margin-bottom: $spacing-2xl;
  }
}

.vp-time-update {
  color: $danger;
  font-weight: $fw-bold;
}

.vp-free-entry {
  margin-bottom: $spacing-2xl;
}

@media #{$desktop-and-above} {
  .vp-event-detail {
    &::before {
      content: 'desktop';
    }

    > .container {
      padding-left: calc(#{$spacing-10xl} + 20px); // Spacing + padding-left value of .container
    }
  }

  .vp-event-single-container {
    --bs-gutter-x: 0.75rem;
  }

  .vp-breadcrumbs {
    margin-bottom: $spacing-12xl;

    .vp-breadcrumb svg.half-dots {
      height: 1rem;
    }
  }

  .vp-event-title {
    font-size: $fs-9xl;
    margin-bottom: $spacing-5xl;
  }

  .vp-event-details {
    font-size: $fs-2xl;
    line-height: $lh-regular;
    margin-bottom: $spacing-5xl;
  }

  .vp-featured-image-container {
    margin-bottom: $spacing-5xl;
  }

  .vp-featured-image-credit {
    font-size: $fs-sm;
  }

  .vp-intro {
    line-height: $lh-regular;
    margin-bottom: $spacing-4xl;
  }

  .vp-event-text {
    margin-bottom: $spacing-5xl;

    h2 {
      margin-bottom: $spacing-4xs;
    }

    p,
    ul,
    ol,
    div {
      margin-bottom: $spacing-5xl;
    }
  }

  .oc-table-title {
    font-size: $fs-3xl;
    font-weight: $fw-bold;
    line-height: $lh-2xs;
    margin-bottom: $spacing-lg;
  }

  .oc-table {
    margin-bottom: $spacing-lg;

    th {
      display: table-cell;
      font-size: $fs-xs;
      line-height: $lh-lg;
      text-align: left;
    }

    tr {
      font-size: $fs-regular;

      td {
        padding: $spacing-default 0;

        &:first-child {
          padding-left: $spacing-lg;
        }

        &:not(:first-child) {
          padding-right: $spacing-lg;
        }
      }
    }
  }

  .vp-price-condition {
    font-size: $fs-regular;
  }

  .vp-time-information,
  .vp-price-information {
    color: $grey-alt;
    font-size: $fs-lg;

    &:not(:last-child) {
      margin-bottom: $spacing-xs;
    }

    &:last-child {
      margin-bottom: $spacing-8xl;
    }
  }

  .vp-free-entry {
    margin-bottom: $spacing-lg;
  }
}
</style>
